import React, { useRef, lazy, Suspense } from 'react'
import { Script, ScriptStrategy } from 'gatsby'
// import useOnScreen from '../hooks/useOnScreen'
import config from '../../data/SiteConfig'
import { NewsLetter } from '../components/LinkCoponents'
import { ButtonLink } from '../components/Button'
import { socialProfilesToShow } from '../../data/socialProfiles'
import { sanitizeUrl } from '../utils/global'

const Layout = lazy(() => import('../layout'))
const TextChanger = lazy(() => import('../components/TextChanger'))
const SocialList = lazy(() => import('../components/SocialList'))

const calendlyIframeStyle = {
  width: '100%',
  minWidth: '320px',
  minHeight: '630px',
}

const handleScroll = (ref) => () =>
  ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' })

function Contact({ location }) {
  const calendlyRef = useRef(null)
  const oneOnOneref = useRef(null)
  const newsletterRef = useRef(null)
  // const onScreenCalendly = useOnScreen(calendlyRef, '10px')
  // const onScreenNewsLetter = useOnScreen(newsletterRef, '10px')
  const pageTitle = `Contact – ${config.siteTitle} ways to contact ${config.siteTitle}`
  
  // Sanitize location.href to prevent DOM XSS
  const safeHref = location?.href ? sanitizeUrl(location.href) : '';
  
  return (
    <Suspense fallback={<span />}>
      <Layout
        title={pageTitle}
        seoDescription={`Ways to connect with ${config.siteTitle}`}
        href={safeHref}
      >
        <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet" />
        <Script
          strategy={ScriptStrategy.idle}
          src="https://assets.calendly.com/assets/external/widget.js"
          type="text/javascript"
        />
        <div className="container">
          <h1>Contact</h1>
          <p>
            I best respond on emails, However keep your email short, simple, and direct and
            I&apos;ll make sure to get back to you. try to send one on{' '}
            <ButtonLink
              simpleLink
              href="mailto:contact@ashmeetsehgal.com?&subject=ashmeetsehgal.com||your-subject-here&body=Hi Ashmeet, Please-start-writing-here"
              title="email"
              target="_blank"
              rel="noopener noreferrer"
            >
              contact@ashmeetsehgal.com
            </ButtonLink>{' '}
            otherwise DM on twitter. if you&apos;d like to catch up with me regarding any
            opportunity.
            <ul>
              <li>In case you want me to plan/setup your new project or to review your PR</li>
              <li>Career Advice, Emotional/Mental stress, don&apos;t wait </li>
            </ul>
            you can arrange an 1 on 1{' '}
            <ButtonLink simpleLink onClick={handleScroll(calendlyRef, '1on1')}>
              Let&apos;s Meet Ash
            </ButtonLink>{' '}
            [check below for more details]. And for regular guidance articles subscribe to this{' '}
            <ButtonLink simpleLink onClick={handleScroll(newsletterRef, 'newsletter')}>
              Newsletter
            </ButtonLink>
          </p>
          <section className="section">
            <h2>You can connect with me on different channels</h2>
            <Suspense fallback={<span />}>
              <SocialList socialList={socialProfilesToShow} />
            </Suspense>
          </section>
          <section className="section">
            <h2 ref={newsletterRef} id="newsletter">
              Newsletter
            </h2>
            <p>
              If you like what I write, the chances are you would love what I email. Consider
              subscribing to my mail. If you&apos;d prefer to be contacted by me, you can get
              notified when I&apos;ve written or created something new via the newsletter. I
              generally send out an email once a month or less, and you can one-click unsubscribe at
              any time.
            </p>
            <NewsLetter />
          </section>
          <section className="section">
            <h2 ref={oneOnOneref} id="1on1" className="inline">
              Let&apos;s Meet Ash to have discussion on 👇{' '}
            </h2>
            <Suspense fallback={<span />}>
              <TextChanger
                textArray={[
                  'Technical Projects',
                  'Mental Health',
                  'Career Guidance',
                  'Basic Finance',
                ]}
              />
            </Suspense>
            <div ref={calendlyRef}>
              <iframe
                title="1on1"
                loading="lazy"
                className="calendly-inline-widget"
                src="https://calendly.com/ashmeetsehgal?hide_gdpr_banner=1"
                style={calendlyIframeStyle}
                frameBorder="0"
                scrolling="no"
              />
            </div>
          </section>
        </div>
      </Layout>
    </Suspense>
  )
}

export default Contact
